export function useLegacyAutoLogin() {
  const event = useRequestEvent()

  const tokenCookie = useCookie('autologin', {
    httpOnly: false,
    secure: false,
    sameSite: 'lax',
    path: '/',
  })

  const token = computed(() => {
    if (import.meta.server && event?.context.newAutoLogin) {
      return event.context.newAutoLogin as string | undefined
    }

    return tokenCookie.value
  })

  return {
    token,
  }
}
