export default defineNuxtRouteMiddleware(async (to) => {
  const notificationsStore = useNotificationsStore()
  const ssoResult = to.query.SSOResult

  // Handle SSOResult used after logging in with SSO
  if (ssoResult && typeof ssoResult === 'string') {
    // First time login
    if (ssoResult === 'Success') {
      notificationsStore.add({
        translationKey: 'WelcomeTextSSO',
        type: 'success',
        showSubscribeButton: true,
      })
      return
    } else if (ssoResult === 'SuccessSSOUserAlreadyExists') {
      // Logged in before, no need to show a modal
      return
    } else if (ssoResult === 'EmailNotSSODomainClient') {
      // Logged in with wrong SSO Domain
      notificationsStore.add({
        translationKey: 'sso.NotSSOEmail',
        type: 'error',
      })
      return
    }
    notificationsStore.add({
      translationKey: 'unknownError',
      type: 'error',
    })
  }
})
