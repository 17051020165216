import type {
  User,
  UserSubscriptionDto,
  UserBranch,
  UserTitle,
  EducationDto,
  relUserDecisionChainDto,
  ArticleUserDTO,
  NewsletterDto,
} from '~/typesAuto/apicore/v1'
import { CountryDto } from '~/typesAuto/apicore/v2'

// Partial apiCore User. Remove when proper partial field utility is implemented
export type MinimalUser = {
  id?: number
  client?: { id: number; jobPlace: string; zipcode: string }
  name?: string | null
  workEmail: string
  countryId?: number | null
  readingList?: ArticleUserDTO[]
  zipcode?: string | null
  birthyear?: number | null
  educationId?: number | null
  workEmailConfirmed?: number
  avatar?: string | null
  contactEmail?: string | null
  contactPhone?: string | null
  userTitleId?: number | null
  userBranchId?: number | null
  autoLogin: string
}

export default () => {
  const { apiCoreFetch, identityApiFetch } = useApiFetch()

  const config = useRuntimeConfig()

  return {
    async getUser(id: number) {
      return await apiCoreFetch<MinimalUser>(`/v2/users/${id}`)
    },
    getPositions() {
      return apiCoreFetch<UserTitle[]>('/usertitles')
    },
    getUserPosition(id: number) {
      return apiCoreFetch<UserTitle>(`/usertitles/${id}`)
    },
    getEducation() {
      return apiCoreFetch<EducationDto[]>('/educations')
    },
    getIndustries() {
      return apiCoreFetch<UserBranch[]>('/userbranches')
    },
    getUserIndustry(id: number) {
      return apiCoreFetch<UserBranch>(`/userbranches/${id}`)
    },
    getCountries() {
      return apiCoreFetch<CountryDto[]>('/v2/countries')
    },
    async updateUser(id: number, patches: unknown[]) {
      try {
        await apiCoreFetch<User>(`/users/${id}`, {
          method: 'PATCH',
          body: patches,
          headers: { 'content-type': 'application/json-patch+json' },
        })

        return {
          message: 'YourAccountInformationHasBeenSaved',
          type: ALERT_TYPE.SUCCESS,
        }
      } catch (error) {
        return {
          message: 'ChangesToUserHasNotBeenSaved',
          type: ALERT_TYPE.ERROR,
        }
      }
    },

    async sendPasswordResetEmail(email: string) {
      try {
        await apiCoreFetch(`/users/forgotPassword`, {
          params: {
            email,
            latestUrl: `https://${config.public.site.url}/userforgotpassword`,
          },
        })

        return {
          message: 'EmailSent',
          type: ALERT_TYPE.SUCCESS,
        }
      } catch (error) {
        const typedError = error as { response?: { status?: number } }

        if (typedError.response?.status !== 410) {
          return {
            message: 'errorOccurred',
            type: ALERT_TYPE.ERROR,
          }
        }

        try {
          await identityApiFetch(`/v1/users/sendchangepassword`, {
            method: 'POST',
            body: {
              callingDomain: config.public.site.domainfull,
              email,
            },
          })

          return {
            message: 'EmailSent',
            type: ALERT_TYPE.SUCCESS,
          }
        } catch (error) {
          return {
            message: 'errorOccurred',
            type: ALERT_TYPE.ERROR,
          }
        }
      }
    },
    async changePassword(
      passwordChangeToken: string,
      newPassword: string,
      userGuid?: string
    ) {
      try {
        if (userGuid) {
          await identityApiFetch(`/v1/users/${userGuid}/changepassword`, {
            method: 'POST',
            body: {
              passwordChangeToken,
              newPassword,
            },
          })
        } else {
          await apiCoreFetch('/users/changePassword', {
            method: 'POST',
            body: {
              confirmationToken: passwordChangeToken,
              newPassword,
            },
          })
        }

        return {
          message: 'PasswordHasBeenUpdated',
          type: ALERT_TYPE.SUCCESS,
        }
      } catch (error) {
        return {
          message: 'user.errors.EmailTokenDoesNotExist',
          type: ALERT_TYPE.ERROR,
        }
      }
    },
    async getDecisionChains(userId: number) {
      return apiCoreFetch<relUserDecisionChainDto[]>(
        `/users/${userId}/decisionchains`
      )
    },
    async followDecisionChain(userId: number, decisionChainId: number) {
      return apiCoreFetch<relUserDecisionChainDto[]>(
        `/users/${userId}/decisionchains/${decisionChainId}`,
        {
          method: 'POST',
        }
      )
    },
    async unfollowDecisionChain(userId: number, decisionChainId: number) {
      return apiCoreFetch<relUserDecisionChainDto[]>(
        `/users/${userId}/decisionchains/${decisionChainId}`,
        {
          method: 'DELETE',
        }
      )
    },
    async getSubscribedNewsletters(userId: number) {
      return await apiCoreFetch<UserSubscriptionDto[]>(
        `users/${userId}/subscriptions`
      )
    },
    async subscribePaidNewsletter(email: string, paperId: string) {
      await apiCoreFetch(`/newsletters/paid/papers/${paperId}/subscribe`, {
        method: 'POST',
        body: {
          email: email,
        },
      })
    },
    async unsubscribePaidNewsletter(email: string, paperId: string) {
      await apiCoreFetch(`/newsletters/paid/papers/${paperId}/unsubscribe`, {
        method: 'POST',
        body: {
          email: email,
        },
      })
    },
    async getFreeNewsletters(userId: number) {
      return await apiCoreFetch<NewsletterDto[]>(
        `users/${userId}/newsletters/free`
      )
    },
    async subscribeFreeNewsletter(email: string) {
      await apiCoreFetch(`/newsletters/free/1/subscribe`, {
        method: 'POST',
        body: {
          email: email,
          ref: 'user profile',
        },
      })
    },
    async unsubscribeFreeNewsletter(email: string) {
      await apiCoreFetch(`/newsletters/free/1/unsubscribe`, {
        method: 'DELETE',
        body: {
          email: email,
        },
      })
    },
  }
}
